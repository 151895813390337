import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/reset.scss";
import "./styles/base.scss";
import "./styles/common.scss";
import "./utils/flexible";
import "vant/lib/index.css";
import * as moment from "moment";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.min.css";
import { VueMasonryPlugin } from "vue-masonry";

import {
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  Field,
  Button,
  Form,
  DatetimePicker,
  Popup,
  Icon,
  Dialog,
  Uploader,
  Picker,
  Toast,
  Search,
  Empty,
  Loading,
  Radio,
  RadioGroup,
  Checkbox,
  Overlay,
  Step,
  Steps,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  List
} from "vant";

Vue.use(ElementUI, {
  size: "mini"
});
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.use(Tab)
  .use(Tabs)
  .use(Collapse)
  .use(CollapseItem)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Button)
  .use(Form)
  .use(DatetimePicker)
  .use(Popup)
  .use(Icon)
  .use(Dialog)
  .use(Uploader)
  .use(Picker)
  .use(Toast)
  .use(Search)
  .use(Empty)
  .use(Loading)
  .use(Radio)
  .use(RadioGroup)
  .use(Checkbox)
  .use(Overlay)
  .use(Steps)
  .use(Step)
  .use(List)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(VueMasonryPlugin)
  
const bus = new Vue();
Vue.prototype.$bus = bus;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
