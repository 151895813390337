import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    auths:[],
    infoMedal:{},
    infoDepament: {},
    cardetailInfo:{}
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload;
    },
    changeAuths(state, payload) {
      state.auths = payload;
    },
    changeInfoMedal(state, payload) {
      state.infoMedal = payload;
    },
    changeInfoDepament(state, payload) {
      state.infoDepament = payload;
    },
    saveCarDetailInfo(state, payload) {
      state.cardetailInfo=payload
    }
  },
  actions: {
    getCarDetailInfo({commit},carInfo) {
      commit('saveCarDetailInfo',carInfo)
    }
  },
  modules: {},
  plugins:[
    createPersistedState({
      storage: window.localStorage,
      key: "store",
      render(val){
        return {
          infoMedal : val.infoMedal
        }
      }
    })
  ]
});
