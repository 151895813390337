<template id="tid">
  <!-- <router-view /> -->
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import "@/styles/public.css";
import "@/styles/animate.min.css";
import "@/styles/swiper.min.css";
export default {
  name: "app",
  methods: {
   
  },
  created() {
    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },
  mounted() { 
  }
};
</script>

<style lang="scss">
html,
body,
#tid {
  height: 100%;
}
</style>

