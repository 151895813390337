import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// 解决编程式导航报错问题
const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, okCallback, errCallback) {
  if (okCallback === undefined && errCallback === undefined) {
    originPush.call(this, location).catch(() => {});
  } else {
    originPush.call(this, location, okCallback, errCallback);
  }
};
VueRouter.prototype.replace = function (location, okCallback, errCallback) {
  if (okCallback === undefined && errCallback === undefined) {
    originReplace.call(this, location).catch(() => {});
  } else {
    originReplace.call(this, location, okCallback, errCallback);
  }
};

const routes = [
  {
    path: "/",
    component: () => import("../layout"),
    redirect: "/regards",
    children: [
      {
        path: "/regards",
        name: "regards",
        component: () => import("../views/regards"),
        meta: {
          title: "关于合力"
        }
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/product"),
        meta: {
          title: "照明产品"
        }
      },
      {
        path: "/global",
        name: "global",
        component: () => import("../views/global"),
        meta: {
          title: "全球合作伙伴"
        }
      },
      {
        path: "/relation",
        name: "relation",
        component: () => import("../views/relation"),
        meta: {
          title: "联系我们"
        }
      },
      {
        path: "/characteristic",
        name: "characteristic",
        component: () => import("../views/characteristic"),
        meta: {
          title: "公司特色"
        }
      },
      {
        path: "/newProduct",
        name: "newProduct",
        component: () => import("../views/newProduct"),
        meta: {
          title: "新品发布"
        }
      },
      {
        path: "/womenDetail",
        name: "womenDetail",
        component: () => import("../views/womenDetail"),
        meta: {
          title: "产品详情"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.body.style.background = to.meta.bgColor
    ? to.meta.bgColor
    : "#ffffff";
  var docEl = document.documentElement;
  var rem;
  if (docEl.clientWidth > 600) {
    rem = docEl.clientWidth / 15;
  } else {
    rem = docEl.clientWidth / 5;
  }
  docEl.style.fontSize = rem + "px";
  window.addEventListener("resize", () => {
    var docEl = document.documentElement;
    var rem;
    if (docEl.clientWidth > 600) {
      rem = docEl.clientWidth / 15;
    } else {
      rem = docEl.clientWidth / 5;
    }
    docEl.style.fontSize = rem + "px";
  });
  document.title = to.meta.title;
  next();
});

export default router;
